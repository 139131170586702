'use strict';

var basePage = require('./base-page.js');
var captionPanels = require('./components/caption-panels.js');
var gridHeights = require('./ui/grid-heights.js');
var images = require('./ui/images.js');

basePage.init = function() {
    basePage.initBasePage();
    
    gridHeights.init();
    images.init();
    captionPanels.init();
};

module.exports = basePage;